import * as React from 'react'
import { createSvgIcon, styled } from '@mui/material'

export default styled(
  createSvgIcon(
    <React.Fragment>
      <path
        d='M14.8534 17.4934H5.54667C4.13334 17.4934 3 16.3468 3 14.9468V5.62675C3 4.22675 4.13334 3.08008 5.54667 3.08008H14.8534C16.2667 3.08008 17.4 4.22675 17.4 5.62675V14.9334C17.4 16.3468 16.2667 17.4934 14.8534 17.4934Z'
        stroke='#91B0FA'
        strokeMiterlimit='10'
      />
      <path d='M3 6.62695H17.4' stroke='#91B0FA' strokeMiterlimit='10' />
      <path
        d='M6.50684 3.08V2'
        stroke='#91B0FA'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M13.8936 3.08V2'
        stroke='#91B0FA'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M6.62633 10.0534C6.97979 10.0534 7.26633 9.7669 7.26633 9.41344C7.26633 9.05998 6.97979 8.77344 6.62633 8.77344C6.27287 8.77344 5.98633 9.05998 5.98633 9.41344C5.98633 9.7669 6.27287 10.0534 6.62633 10.0534Z'
        fill='#91B0FA'
      />
      <path
        d='M10.1996 10.0534C10.553 10.0534 10.8396 9.7669 10.8396 9.41344C10.8396 9.05998 10.553 8.77344 10.1996 8.77344C9.84611 8.77344 9.55957 9.05998 9.55957 9.41344C9.55957 9.7669 9.84611 10.0534 10.1996 10.0534Z'
        fill='#91B0FA'
      />
      <path
        d='M13.7728 10.0534C14.1263 10.0534 14.4128 9.7669 14.4128 9.41344C14.4128 9.05998 14.1263 8.77344 13.7728 8.77344C13.4194 8.77344 13.1328 9.05998 13.1328 9.41344C13.1328 9.7669 13.4194 10.0534 13.7728 10.0534Z'
        fill='#91B0FA'
      />
      <path
        d='M6.62633 12.573C6.97979 12.573 7.26633 12.2864 7.26633 11.933C7.26633 11.5795 6.97979 11.293 6.62633 11.293C6.27287 11.293 5.98633 11.5795 5.98633 11.933C5.98633 12.2864 6.27287 12.573 6.62633 12.573Z'
        fill='#91B0FA'
      />
      <path
        d='M10.1996 12.573C10.553 12.573 10.8396 12.2864 10.8396 11.933C10.8396 11.5795 10.553 11.293 10.1996 11.293C9.84611 11.293 9.55957 11.5795 9.55957 11.933C9.55957 12.2864 9.84611 12.573 10.1996 12.573Z'
        fill='#91B0FA'
      />
      <path
        d='M13.7728 12.573C14.1263 12.573 14.4128 12.2864 14.4128 11.933C14.4128 11.5795 14.1263 11.293 13.7728 11.293C13.4194 11.293 13.1328 11.5795 13.1328 11.933C13.1328 12.2864 13.4194 12.573 13.7728 12.573Z'
        fill='#91B0FA'
      />
      <path
        d='M6.62633 15.0925C6.97979 15.0925 7.26633 14.806 7.26633 14.4525C7.26633 14.099 6.97979 13.8125 6.62633 13.8125C6.27287 13.8125 5.98633 14.099 5.98633 14.4525C5.98633 14.806 6.27287 15.0925 6.62633 15.0925Z'
        fill='#91B0FA'
      />
      <path
        d='M10.1996 15.0925C10.553 15.0925 10.8396 14.806 10.8396 14.4525C10.8396 14.099 10.553 13.8125 10.1996 13.8125C9.84611 13.8125 9.55957 14.099 9.55957 14.4525C9.55957 14.806 9.84611 15.0925 10.1996 15.0925Z'
        fill='#91B0FA'
      />
      <path
        d='M13.7728 15.0925C14.1263 15.0925 14.4128 14.806 14.4128 14.4525C14.4128 14.099 14.1263 13.8125 13.7728 13.8125C13.4194 13.8125 13.1328 14.099 13.1328 14.4525C13.1328 14.806 13.4194 15.0925 13.7728 15.0925Z'
        fill='#91B0FA'
      />
    </React.Fragment>,
    'Calender'
  )
)({
  fill: 'transparent'
})
