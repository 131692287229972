import * as React from 'react'
import { createSvgIcon, styled } from '@mui/material'

export default styled(
  createSvgIcon(
    <React.Fragment>
      <line
        x1='3.65'
        y1='8.35'
        x2='20.35'
        y2='8.35'
        stroke='#666666'
        stroke-width='1.3'
        strokeLinecap='round'
      />
      <line
        x1='3.65'
        y1='15.35'
        x2='20.35'
        y2='15.35'
        stroke='#666666'
        stroke-width='1.3'
        strokeLinecap='round'
      />
    </React.Fragment>,
    'ReorderColumn'
  )
)({
  fill: 'transparent'
})
