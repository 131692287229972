import React from 'react'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import { Box, Typography } from '@mui/material'

const ExportDataDialog = ({ onClose, open, onSave, startDate, endDate }) => {
  return (
    <Dialog
      width={496}
      height={144}
      open={open}
      onClose={onClose}
      showDialogFooter
      title='Export Data'
      buttonAction={
        <Box display={'flex'} gap={3}>
          <Button text='Cancel' variant='outlined' onClick={onClose} />
          <Button text='Export' color='success' onClick={onSave} />
        </Box>
      }
    >
      <Box p={4}>
        <Typography my={1} variant='body1'>
          {`THIS ACTION WILL DOWNLOAD DATA FROM ${startDate} to ${endDate}`}
        </Typography>
        <Typography variant='body1'>
          Are you sure you want to Proceed?
        </Typography>
      </Box>
    </Dialog>
  )
}

export default ExportDataDialog
