import React from 'react'
import { TextField } from '@mui/material'
import IconButton from './IconButton'
import SearchIcon from '../icons/SearchIcon'
import Cut from '../icons/Cut'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  focused: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: `${theme.palette.primary.main} !important`
    }
  },
  root: {
    '&:hover  .MuiOutlinedInput-notchedOutline': {
      borderColor: '#BFD2FD'
    }
  },
  input: {
    width: 300,
    height: 32,
    border: '1px solid #c7c7c7',
    borderRadius: 4,
    padding: 0,
    paddingLeft: 12,
    color: 'black',
    ...theme.typography.body2,
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'black',
      ...theme.typography.body2
    },
    '&:focus': {
      outlineColor: theme.palette.primary.main
    }
  },
  iconButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    zIndex: ({ buttonVariant }) => (buttonVariant === 'outlined' ? 0 : 1),
    border: ({ buttonVariant }) =>
      buttonVariant === 'outlined' ? 'none' : '1px solid 2C63E5',
    borderLeft: ({ buttonVariant }) =>
      buttonVariant === 'outlined' ? '1px solid #BFD2FD' : 'none',
    height: 33
  },
  icon: { cursor: 'pointer', color: 'white', padding: '0 5px' },
  adornedEnd: {
    paddingRight: 0
  }
}))

const Searchbar = ({
  buttonVariant = 'contained',
  onEnter = () => {},
  onChange,
  inputProps,
  placeholder,
  buttonProps,
  value,
  cutClick = () => {},
  ...otherProps
}) => {
  const classes = useStyles({ buttonVariant })
  return (
    // @ts-ignore
    <TextField
      value={value}
      variant='outlined'
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={(e) => e.keyCode === 13 && onEnter()}
      InputProps={{
        classes: {
          root: classes.root,
          adornedEnd: classes.adornedEnd,
          focused: classes.focused
        },
        endAdornment: (
          <>
            {value && (
              <IconButton
                variant='text'
                size='small'
                disableElevation
                icon={<Cut color={'#666666'} fontSize='small' />}
                onClick={cutClick}
              />
            )}
            <IconButton
              variant={buttonVariant}
              size='small'
              color='primary'
              className={classes.iconButton}
              disableElevation
              icon={
                <SearchIcon
                  color={buttonVariant === 'contained' ? '#fff' : '#2C63E5'}
                  fontSize='small'
                />
              }
              onClick={onEnter}
              {...buttonProps}
            />
          </>
        )
      }}
      style={{
        width: '200px'
      }}
      inputProps={{
        style: {
          background: 'white',
          height: '32px',
          border: 'none',
          borderTopRightRadius: '0',
          borderBottomRightRadius: '0'
        },
        className: classes.input,
        ...inputProps
      }}
      {...otherProps}
    />
  )
}

export default Searchbar
