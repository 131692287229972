import * as React from 'react'
import { createSvgIcon, styled } from '@mui/material'

export default styled(
  createSvgIcon(
    <React.Fragment>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.2'
        d='M22.031 8.64c.352.98.546 2.03.546 3.125 0 5.339-4.6 9.674-10.294 9.674-2.72 0-5.184-.993-7.03-2.601'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.2'
        d='m5.326 21.53-.315-2.92 3.095-.308'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.2'
        d='M2.51 14.788A9.218 9.218 0 0 1 2 11.765c0-5.339 4.601-9.674 10.295-9.674 2.719 0 5.183.993 7.029 2.601'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.2'
        d='m19.251 2 .328 2.92-3.108.308'
      />
    </React.Fragment>,
    'Refresh'
  )
)({
  fill: 'transparent'
})
