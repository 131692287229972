import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DownloadIcon = ({ color, ...otherProps }) => {
  return (
    <SvgIcon
      viewBox="0 0 18 17"
      fill="none"
      style={{ width: '16px', height: '16px' }}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5003 11.8273C16.9277 11.8273 17.2799 12.149 17.3281 12.5635L17.3337 12.6606V14.3273C17.3337 15.6587 16.2929 16.747 14.9806 16.8231L14.8337 16.8273H3.16699C1.83559 16.8273 0.747275 15.7865 0.671236 14.4742L0.666992 14.3273V12.6606C0.666992 12.2004 1.04009 11.8273 1.50033 11.8273C1.92769 11.8273 2.27991 12.149 2.32805 12.5635L2.33366 12.6606V14.3273C2.33366 14.7547 2.65536 15.1069 3.06981 15.155L3.16699 15.1606H14.8337C15.261 15.1606 15.6132 14.8389 15.6614 14.4245L15.667 14.3273V12.6606C15.667 12.2004 16.0401 11.8273 16.5003 11.8273ZM9.00033 0.160645C9.46056 0.160645 9.83366 0.533741 9.83366 0.993978V8.98148L10.9111 7.90472C11.2115 7.60432 11.6842 7.58121 12.0111 7.8354L12.0896 7.90472C12.39 8.20513 12.4131 8.67782 12.1589 9.00473L12.0896 9.08323L9.58958 11.5832L9.55295 11.6177L9.49641 11.6637L9.40371 11.7234L9.30977 11.768L9.22199 11.7974L9.09822 11.8216L9.00033 11.8273L8.93763 11.825L8.83305 11.8104L8.74021 11.7859L8.64764 11.7493L8.5664 11.7056L8.48637 11.65C8.45994 11.6293 8.43479 11.607 8.41107 11.5832L5.91107 9.08323C5.58563 8.7578 5.58563 8.23016 5.91107 7.90472C6.21147 7.60432 6.68417 7.58121 7.01108 7.8354L7.08958 7.90472L8.16699 8.98148V0.993978C8.16699 0.533741 8.54009 0.160645 9.00033 0.160645Z"
        fill={color || 'currentColor'}
      />
    </SvgIcon>
  );
};

export default DownloadIcon;
