import React, { useState } from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import MuiTypography from '@mui/material/Typography'

import { ClickAwayListener, Popper } from '@mui/material'

const DropdownPopper = ({ anchorEl, setAnchorEl, children, parentElement }) => {
  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        {parentElement}
        <Popper
          id='main-menu'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement='bottom-start'
          style={{ zIndex: 999999 }}
        >
          {children}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default function SplitButton({
  text,
  handleMenuItemClick,
  handleButtonClick,
  options,
  buttonProps = {}
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleToggle = (e) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  return (
    <DropdownPopper
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      children={
        <Paper sx={{ width: 154, marginLeft: 50 }}>
          <MenuList id='split-button-menu' autoFocusItem>
            {options.map((option, index) => (
              <MenuItem
                key={option}
                onClick={(event) => {
                  handleMenuItemClick(event, index)
                  setAnchorEl(null)
                }}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      }
      parentElement={
        <ButtonGroup
          variant='contained'
          ref={anchorEl}
          aria-label='split button'
          disableElevation
        >
          <Button
            sx={{ height: '32px' }}
            onClick={() => handleButtonClick()}
            {...buttonProps}
          >
            <MuiTypography variant='h5'>{text}</MuiTypography>
          </Button>
          <Button
            size='small'
            aria-controls={Boolean(anchorEl)}
            aria-expanded={Boolean(anchorEl)}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            onClick={(e) => handleToggle(e)}
            sx={{ width: '32px' }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      }
    />
  )
}
