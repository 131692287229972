import React, { useState } from 'react'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import { Box, TextField } from '@mui/material'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const EditPenaltyDialog = ({ onClose, open, onSave, penalties = [] }) => {
  const [penaltyType, setPenaltyType] = useState('')
  const [newPenalty, setNewPenalty] = useState('')
  const [reason, setReason] = useState('')

  const handleChange = (event) => {
    setPenaltyType(event.target.value)
  }
  return (
    <Dialog
      width={550}
      height={280}
      open={open}
      onClose={onClose}
      showDialogFooter
      title='Edit Penalty'
      buttonAction={
        <Box display={'flex'} gap={3}>
          <Button text='Cancel' variant='outlined' onClick={onClose} />
          <Button
            text='Edit'
            color='success'
            onClick={() => onSave({ [penaltyType]: newPenalty, reason })}
          />
        </Box>
      }
    >
      <Box p={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl sx={{ minWidth: 300 }} size='medium'>
          <InputLabel id='demo-select-medium'>Penalty Type</InputLabel>
          <Select
            labelId='demo-select-small'
            id='demo-select-small'
            value={penaltyType}
            label='penaltyType'
            onChange={handleChange}
          >
            {penalties.map((item) => (
              <MenuItem key={item.header} value={item.field}>
                {item.header}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          minWidth={290}
          id='outlined-basic'
          label='New Penalty'
          variant='outlined'
          value={newPenalty}
          onChange={(e) => setNewPenalty(e.target.value)}
        />
        <TextField
          minWidth={290}
          id='outlined-basic'
          label='Reason'
          variant='outlined'
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </Box>
    </Dialog>
  )
}

export default EditPenaltyDialog
