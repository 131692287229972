import { useEffect, useState } from 'react'

const DEFAULT_ERROR_MESSAGE = 'Invalid Value'
const DEFAULT_REQUIRED_MESSAGE = 'This field is required'

/**
 * useForm hook is bulid for basic form management for Intugine Component library.
 *  Easy to manage, provides readymade reusable functions, and inbuilt validation support
 * @param initialFormState use it to initilize your form, Optional
 * @param options form options
 * @returns object of state and hepler functions
 */
const useForm = (
  initialFormState = {},
  options = {
    debugging: true
  }
) => {
  const [form, setForm] = useState({ ...initialFormState })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    console.log({ form, errors })
  }, [form])

  /**
   *
   * @param errors map of form keys and error string, eg {name: "must be > 4 chars", phoneNo: "must be 10 digits"}
   * @returns void
   */
  const setAllErrors = (errors) => setErrors({ ...errors })

  /**
   * For setting a custom error to a particular key
   * @param key type TFormKey
   * @param error error string
   * @returns void
   */
  const setError = (key, error) =>
    setErrors((errors) => ({ ...errors, [key]: error }))

  /**
   * for reinitialized the form state. returns the reinitialized state
   * @param newInitialState New Initial State. if passed, it will replace the actual initial state
   */
  const initForm = (newInitialState) => {
    const newValue = {
      ...(newInitialState ?? initialFormState ?? {})
    }
    setForm(newValue)
    setErrors({})
    return newValue
  }

  /**
   * use it if there is no prebuild function to handle a specific case, or you want to update many values at once.
   * @param fields map of form keys and values, example => {name: "Nikhil", email: "nikhil121sati.5@gmail.com"}
   */
  const setFields = (fields) => {
    setForm((form) => ({ ...form, ...fields }))
  }

  /**
   * clearForm will initilize the form to {}.
   * Warning => Clearing the complete state might lead to uncontrolled components
   */
  const clearForm = () => {
    setForm({})
    setErrors({})
  }

  const valueSetter = (key, value, options) => {
    let message = ''
    let isValid = false
    if (value) {
      isValid = options?.validate
        ? options.validate(value)
        : options?.regex?.test(value) ?? true
      message = isValid ? '' : options?.errorMessage || DEFAULT_ERROR_MESSAGE
    } else {
      if (options?.required)
        message = options?.requiredMessage || DEFAULT_REQUIRED_MESSAGE
      else isValid = true
    }

    const formattedValue = options?.formatter ? options.formatter(value) : value
    setForm((form) => ({ ...form, [key]: formattedValue }))
    setErrors((errors) => ({ ...errors, [key]: isValid ? '' : message }))
  }

  /**
   * @param key any key of the form
   * @param options options for validation and formatting, optional
   * @returns void
   */
  const handleTextbox = (key, options) => (e) => {
    const value = e.target.value
    valueSetter(key, value, options)
  }

  /**
   * for toggling and untoggling of any element, eg - radio, switches
   * @param key any key of the form
   * @returns void
   */
  const handleToggle = (key) => () => {
    setForm((form) => ({ ...form, [key]: !form[key] }))
  }

  /**
   * for setting direct value, useful in case of dropdowns, autocomplete and multiselects
   * @param key any key of the form
   * @param options options for validation and formatting, optional
   * @returns void
   */
  const handleValue = (key, options) => (value) => {
    valueSetter(key, value, options)
  }

  return {
    form,
    initForm,
    clearForm,
    handleTextbox,
    handleToggle,
    handleValue,
    setFields,
    errors,
    setAllErrors,
    setError
  }
}

export default useForm
