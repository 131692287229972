import MuiPagination from '@mui/material/Pagination'

const Pagination = ({ onPageChange, count = 1, page = 1, ...otherProps }) => {
  return (
    <MuiPagination
      count={count}
      page={page}
      shape='rounded'
      color='primary'
      onChange={(_, page) => {
        onPageChange?.(page)
      }}
      {...otherProps}
    />
  )
}

export default Pagination
