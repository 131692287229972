import { createTheme, adaptV4Theme } from '@mui/material';

const typography = {
  fontFamily: "'Poppins', sans-serif",
  h1: {
    fontSize: 32,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    lineHeight: '48px',
  },
  h2: {
    fontSize: 24,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    lineHeight: '36px',
  },
  h3: {
    fontSize: 20,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    lineHeight: '32px',
  },
  h4: {
    fontSize: 16,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    lineHeight: '24px',
  },
  h5: {
    fontSize: 12,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 500,
    lineHeight: '20px',
  },
  h6: {
    fontSize: 11,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 500,
    lineHeight: '16px',
  },
  subtitle1: {
    fontSize: 20,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 400,
    lineHeight: '32px',
  },
  subtitle2: {
    fontSize: 16,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 400,
    lineHeight: '24px',
  },
  body1: {
    fontSize: 14,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 400,
    lineHeight: '20px',
  },
  body2: {
    fontSize: 12,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 400,
    lineHeight: '16px',
  },
  display1: {
    fontSize: 64,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 900,
    lineHeight: '96px',
  },
  display2: {
    fontSize: 56,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 900,
    lineHeight: '84px',
  },
  button: {
    textTransform: 'none',
  },
};

export const theme = createTheme(adaptV4Theme({
  palette: {
    mode: 'light',
    primary: {
      light: '#91B0FA',
      main: '#2C63E5',
      dark: '#264CA4',
      contrastText: '#F5F8FF',
      800: '#2A3C66',
      200: '#BFD2FD',
      100: '#D7E3FE',
    },
    secondary: {
      light: '#F2F2F2',
      main: '#C7C7C7',
      dark: '#666666',
      contrastText: '#E3E3E3',
    },
    error: {
      light: '#F9D7D7',
      main: '#FF5757',
      dark: '#CC3333',
    },
    warning: {
      light: '#FFECDB',
      main: '#F2984A',
      dark: '#D97E2E',
    },
    success: {
      light: '#C2FAEA',
      main: '#00C28B',
      dark: '#008F66',
    },
    common: {
      black: '#1A1A1A',
      white: '#fff',
    },
    grey: {
      500: '#666666',
      300: '#A1A1A1',
      200: '#c7C7C7',
      100: '#E3E3E3',
      50: '#F2F2F2',
    },
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: "light"; primary: { light: string; m... Remove this comment to see the full error message
    blackAndWhite: {
      main: '#1A1A1A',
      light: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  // @ts-expect-error
  typography: {
    ...typography,
  },
}));
