import './App.css'
import NewHome from './pages/home/newHome'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { theme } from './Theme'
import { useEffect, useState } from 'react'
import CustomError from './components/CustomError'
// import Header from './pages/home/components/Header'

function App() {
  const urlToken = new URLSearchParams(window.location.search).get('token')
  const [token, setToken] = useState(null)

  useEffect(() => {
    if (urlToken) {
      setToken(urlToken)
      localStorage.setItem('token', urlToken)
    }
  }, [token])

  if (!localStorage.getItem('token')) {
    console.log('token not found')
    return null
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* <Header /> */}
        <NewHome />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
