import { Typography } from '@mui/material'
import SelectDisplayItemsDialog from './SelectDisplayItemsDialogNew'

export const DefaultActions = ({
  rows,
  columns,
  selectedColumns,
  setSelectedColumns
}) => {
  return (
    <SelectDisplayItemsDialog
      columns={columns}
      setSelectedColumns={setSelectedColumns}
      selectedColumns={selectedColumns}
    />
  )
}
const Header = ({
  title,
  disableMainHeader = false,
  Actions = DefaultActions,
  actionsProps = {},
  SubHeader,
  subHeaderProps,
  rows,
  columns,
  selectedColumns,
  setSelectedColumns,
  showActions = true
}) => {
  return (
    <div>
      {!disableMainHeader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: 58,
            padding: '8px 20px',
            alignItems: 'center',
            border: 'none',
            borderBottom: '1px solid #E3E3E3',
            boxSizing: 'border-box',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
          }}
        >
          {title && (
            <Typography variant='h4' style={{ marginRight: 24 }}>
              {title}
            </Typography>
          )}
          {showActions && (
            <Actions
              rows={rows}
              columns={columns}
              setSelectedColumns={setSelectedColumns}
              selectedColumns={selectedColumns}
              {...actionsProps}
            />
          )}
        </div>
      )}
      {SubHeader && (
        <SubHeader
          rows={rows}
          columns={columns}
          setSelectedColumns={setSelectedColumns}
          {...subHeaderProps}
        />
      )}
    </div>
  )
}

export default Header
