import { format } from 'date-fns'
import { read, utils, writeFile } from 'xlsx'

export const formatDate = (date, options) => {
  if (!date) return options?.fallbackString ?? 'N/A'
  return format(new Date(date), options?.format ?? 'dd-MMM-yyyy, hh:mm a')
}

export const convertExcelToData = (file, sheetName) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        try {
          const workbook = read(e?.target?.result, {
            type: 'binary',
            cellText: false,
            cellDates: true
          })
          if (sheetName) {
            const worksheet = workbook.Sheets[sheetName]
            const json = utils.sheet_to_json(worksheet)
            const aoa = utils.sheet_to_json(worksheet, { header: 1 })
            resolve({ jsonData: json, headers: aoa?.[0], arrayData: aoa })
          } else {
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]

            const json = utils.sheet_to_json(worksheet)
            const aoa = utils.sheet_to_json(worksheet, { header: 1 })
            resolve({ jsonData: json, headers: aoa?.[0], arrayData: aoa })
          }
        } catch {
          reject('Some error Occurred')
        }
      }
      reader.readAsBinaryString(file)
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

export const stringToDate = (dateStr) => {
  console.log(dateStr)
  if (!dateStr) return null
  const [date, time] = dateStr.split(' ')

  try {
    const [month, day, year] = date?.split('/')
    const [hour, minute, seconds] = time?.split(':')

    console.log(
      new Date(
        '20'.concat(year),
        // year,
        month - 1,
        day,
        hour,
        minute,
        seconds ?? null
      )
    )

    return new Date(
      '20'.concat(year),
      // year,
      month - 1,
      day,
      hour,
      minute,
      seconds ?? null
    )
  } catch (e) {
    console.log(e)
    console.log(dateStr)
  }
}

export const convertDataToExcel = (data = [], fileName) => {
  let worksheet = utils.json_to_sheet(data)
  let new_workbook = utils.book_new()
  utils.book_append_sheet(new_workbook, worksheet, 'API')
  writeFile(new_workbook, `${fileName}.XLSX`)
}

export const generateSheetWithErrorData = (rows, sheetName) =>
  convertDataToExcel(rows, sheetName)
