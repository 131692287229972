import React from 'react'
import { MenuItem, Select, Typography } from '@mui/material'
import Pagination from './Pagination'

const Footer = ({
  count = 1,
  onPageChange,
  total = 0,
  page = 1,
  pageSize = 10,
  onPageSizeChange
}) => {
  return (
    <div
      style={{
        padding: '8px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        boxSizing: 'border-box',
        borderTop: '1px solid rgba(224, 224, 224, 1)'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography variant='body2'>Viewing</Typography>
        <Select
          value={pageSize}
          onChange={onPageSizeChange}
          variant='outlined'
          style={{
            height: 24,
            margin: '0 8px',
            boxSizing: 'border-box'
          }}
          sx={{
            '& .MuiSelect-select': {
              padding: '0 14px'
            }
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Typography variant='body2' style={{ marginRight: 24 }}>
          of
          <span
            style={{
              fontFamily: 'Poppins',
              fontWeight: 500,
              padding: '0 4px',
              color: '1A1A1A'
            }}
          >
            {total} records
          </span>
        </Typography>
      </div>
      <div style={{}}>
        <Pagination
          page={page}
          count={count || 1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default Footer
