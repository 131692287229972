import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Btn from '@mui/material/Button';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 32,
  },
  contained: {
    background: ({ color }) => theme.palette[color].main,
    color: theme.palette.common.white,
    '&:hover': {
      background: ({ color }) => theme.palette[color].dark,
    },
  },
  outlined: {
    color: ({ color }) => theme.palette[color].main,
    background: theme.palette.common.white,
    border: ({ color }) => `1px solid ${theme.palette[color].main}`,
    borderColor: ({ color }) => theme.palette[color].main,
    '&:hover': {
      background: ({ color }) => theme.palette[color].contrastText,
    },
  },
  small: {
    width: 32,
    height: 32,
    padding: '8px',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  medium: {
    width: 40,
    height: 40,
    padding: '10px',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  large: {
    width: 48,
    height: 48,
    fontFamily: theme.typography.h4,
    padding: '12px',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
}));

const IconButton = ({
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  icon,
  ...otherProps
}) => {
  const classes = useStyles({ color });
  return (
    <Btn
      variant={variant}
      classes={{
        // @ts-ignore
        root: clsx(classes[size], classes[variant], classes.root),
        startIcon: classes.startIcon,
      }}
      startIcon={icon}
      {...otherProps}
    >
      {otherProps.children}
    </Btn>
  );
};

export default IconButton;
