import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './toast.css'

import Success from '../../icons/Success'

const commonProps = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
}

export const basicToast = (content, options) =>
  toast(content, { ...commonProps, ...(options ?? {}) })

export const successToast = (content, options) =>
  toast(content, {
    ...commonProps,
    autoClose: 2000,
    ...(options ?? {}),
    type: 'success',
    icon: () => (
      <Success
        htmlColor='#fff'
        sx={{
          '& path:last-child': {
            stroke: (theme) => theme.palette.success.dark
          }
        }}
      />
    )
  })

export const pendingSuccessToast = (promise, content, options) =>
  toast.promise(promise, content, {
    ...commonProps,
    autoClose: 2000,
    ...(options ?? {})
  })

export const errorToast = (content, options) =>
  toast(content, { ...commonProps, ...(options ?? {}), type: 'error' })

export const warningToast = (content, options) =>
  toast(content, { ...commonProps, ...(options ?? {}), type: 'warning' })

export default ToastContainer
