import MuiBox from '@mui/material/Box';
import MuiCheckbox from '@mui/material/Checkbox';

import CheckedIcon from '../icons/CheckedIcon';
import UnCheckedIcon from '../icons/UncheckedIcon';

import { useTheme, Typography } from '@mui/material';

const Label = ({ label, required = false, icon, ...otherProps }) => {
  const theme = useTheme();
  return (
    <Typography sx={{ display: 'flex', alignItems: 'center' }} {...otherProps}>
      {icon && <span style={{ marginRight: '8px' }}>{icon}</span>}
      {label}{' '}
      {label && required && (
        <span style={{ color: theme.palette.error.main }}>*</span>
      )}
    </Typography>
  );
};

const Checkbox = ({
  size = 'medium',
  sx,
  label,
  disabled = false,
  alert,
  reverse,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <MuiBox
      sx={[
        {
          display: 'flex',
          color: disabled
            ? theme.palette.grey[100]
            : theme.palette.common.black,
          alignItems: 'center',
          flexDirection: reverse ? 'row-reverse' : 'row',
          width: 'max-content',
        },
        {
          '& svg': {
            fontSize: size === 'medium' ? '16px' : '12px',
            color: theme.palette.grey[350],
            fill: theme.palette.common.white,
          },
          '& .Mui-checked > svg': {
            fontSize: size === 'medium' ? '16px' : '12px',
            color: theme.palette.primary.main,
            fill: theme.palette.common.white,
          },
          '&:hover svg': {
            color: 'primary.main',
            fill: theme.palette.primary[50],
          },
          '&:hover .Mui-checked > svg': {
            color: 'primary.dark',
            storke: theme.palette.primary.dark,
            fill: theme.palette.primary[50],
          },
        },
        alert && {
          '& svg': {
            color: `${theme.palette.error.main} !important`,
            fill: theme.palette.grey[150],
          },
          '&:hover svg': {
            color: 'error.main',
            fill: theme.palette.error.light,
          },
        },
        disabled && {
          color: 'grey.100',
          '& svg': {
            color: `${theme.palette.grey[100]} !important`,
            fill: theme.palette.grey[150],
          },
          '&:hover svg': {
            color: 'grey.100',
            fill: theme.palette.grey[150],
          },
        },
        ...(Boolean(sx) && Array.isArray(sx) ? sx : []),
        Boolean(sx) && !Array.isArray(sx) ? sx : {},
      ]}
    >
      <MuiCheckbox
        checkedIcon={
          <CheckedIcon
            htmlColor={
              disabled ? theme.palette.grey[100] : theme.palette.primary.main
            }
            color="primary"
          />
        }
        icon={<UnCheckedIcon />}
        size={size}
        disabled={disabled}
        disableRipple
        color="primary"
        {...otherProps}
        sx={{
          padding: 0,
          '&:hover': {
            '#unchecked': {
              '&>path': {
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary[50],
              },
            },
          },
        }}
      />
      {!!label && (
        <Label
          label={label}
          variant={size === 'small' ? 'body2' : 'body1'}
          sx={[{ ml: size === 'small' ? '4px' : 1, cursor: 'default' }]}
        />
      )}
    </MuiBox>
  );
};
export default Checkbox;
