import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const getToken = () =>
  localStorage.getItem('token') ?? new URLSearchParams(window.location.search).get('token')

export const penaltyApi = createApi({
  reducerPath: 'penalty',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api/v2`,
    prepareHeaders: (headers) => {
      const token = getToken()
      console.log(token)
      return new Headers({
        Authorization: `Bearer ${token}`
      })
    }
  }),
  tagTypes: ['penalty'],
  endpoints: (builder) => ({
    getPenaltyData: builder.query({
      query: (body) => ({
        url: `penalties`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => {
        return res?.result?.[0]
      },
      providesTags: ['penalty']
    }),
    getConfig: builder.query({
      query: () => ({
        url: `/penalties/config`,
        method: 'GET'
      }),
      transformResponse: (res) => {
        return res?.result?.[0]?.config
      }
    }),
    downloadReport: builder.mutation({
      query: (body) => ({
        url: `penalties/download`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => {
        return res?.result?.[0]
      }
    }),
    recalculatePenalty: builder.mutation({
      query: (body) => ({
        url: `penalties/recalculate`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => {
        return res?.result?.[0]
      },
      invalidatesTags: ['penalty']
    }),
    uploadDispatchData: builder.mutation({
      query: (body) => ({
        url: `penalties/upload-dispatch`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => {
        return res?.result?.[0]
      },
      invalidatesTags: ['penalty']
    }),
    uploadGateData: builder.mutation({
      query: (body) => ({
        url: `penalties/upload-gate-reg`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => {
        return res?.result?.[0]
      },
      invalidatesTags: ['penalty']
    }),
    updatePenalty: builder.mutation({
      query: ({ body, currentId }) => ({
        url: `penalties/${currentId}`,
        method: 'PUT',
        body
      }),
      transformResponse: (res) => {
        return res?.result?.[0]
      },
      invalidatesTags: ['penalty']
    })
  })
})

export const {
  useGetPenaltyDataQuery,
  useGetConfigQuery,
  useDownloadReportMutation,
  useRecalculatePenaltyMutation,
  useUploadDispatchDataMutation,
  useUploadGateDataMutation,
  useUpdatePenaltyMutation
} = penaltyApi
