import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { penaltyApi } from './penalty'

const store = configureStore({
  reducer: {
    [penaltyApi.reducerPath]: penaltyApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(penaltyApi.middleware)
})

setupListeners(store.dispatch)

export default store
