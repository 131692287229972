import * as React from 'react'
import { createSvgIcon, styled } from '@mui/material'

export default styled(
  createSvgIcon(
    <React.Fragment>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
        d="M18.682 2 3.521 17.16l3.341 3.342 15.162-15.16L18.682 2Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
        d="M3.52 17.157 2 21.95l4.86-1.436M12.003 22h10.036M16.996 4.149l2.856 2.855"
      />
    </React.Fragment>,
    'Edit'
  )
)({
  fill: 'transparent'
})
