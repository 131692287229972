import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { DefaultActions } from '../../../components/table/TableHeader'
import Searchbar from '../../../components/Searchbar'
import IconButton from '../../../components/IconButton'
import ExportIcon from '../../../icons/ExportIcon'
import SplitButton from '../../../components/SplitButton'
import Button from '../../../components/Button'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import Tooltip from '@mui/material/Tooltip'
import Refresh from '../../../icons/Refresh'
import Calender from '../../../icons/Calender'
import Edit from '../../../icons/Edit'

export const EditPenalty = ({ onClick }) => {
  return (
    <>
      <IconButton
        size='small'
        variant='text'
        onClick={onClick}
        icon={<Edit color='primary' fontSize='large' />}
      />
    </>
  )
}

export const TableActions = ({
  rows,
  datePickerProps,
  columns,
  searchBoxProps,
  selectedColumns,
  setSelectedColumns,
  uploadSheetButtonProps,
  exportDataButtonProps,
  recalculateButtonProps,
  onRefresh,
  isLoading
  // onRecalculateButtonClick
}) => {
  const { onClick, handleButtonClick } = uploadSheetButtonProps
  const { search, setSearch, globalSearchHandler, onSearchCutIconClick } =
    searchBoxProps
  const { startDate, endDate, handleChange } = datePickerProps
  const handleMenuItemClick = (event, index = 0) => {
    onClick(index)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 56,
        alignItems: 'center',
        width: 'calc(100%)'
      }}
    >
      <Grid
        item
        container
        sx={{ display: 'flex', gap: 2 }}
        justifyContent='flex-start'
        xs={7}
      >
        <Grid item>
          <Searchbar
            placeholder='Search Truck Number'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onEnter={() => globalSearchHandler()}
            cutClick={onSearchCutIconClick}
          />
        </Grid>

        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{ textField: { size: 'small' } }}
              label='Start Date'
              value={dayjs(startDate)}
              onChange={(e) => handleChange('startDate')(e.toISOString())}
              sx={{ width: '150px' }}
              maxDate={dayjs()}
              components={{
                OpenPickerIcon: Calender
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{ textField: { size: 'small' } }}
              label='End Date'
              value={dayjs(endDate)}
              onChange={(e) => handleChange('endDate')(e.toISOString())}
              sx={{ width: '150px' }}
              components={{
                OpenPickerIcon: Calender
              }}
              minDate={dayjs(startDate)}
              maxDate={
                dayjs(new Date(startDate)).add(30, 'day') > dayjs()
                  ? dayjs()
                  : dayjs(new Date(startDate)).add(30, 'day')
              }
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid
        item
        container
        sx={{ display: 'flex', gap: 1 }}
        justifyContent='flex-end'
        xs={5}
      >
        {/* Add other action buttons here.  Use Icon Button */}

        <SplitButton
          text='Upload Sheet'
          options={['Gate Sheet', 'Dispatch Sheet']}
          handleMenuItemClick={handleMenuItemClick}
          handleButtonClick={handleButtonClick}
        />

        <Tooltip
          title={
            <>
              <Typography color='inherit'>Recalculate penalty for</Typography>
              <Typography color='inherit'>selected date range</Typography>
            </>
          }
          size={'small'}
        >
          <div>
            <Button
              text='Recalculate'
              variant='outlined'
              color='primary'
              size='small'
              {...recalculateButtonProps}
              style={{ marginLeft: 10 }}
            />
          </div>
        </Tooltip>

        <Divider variant='middle' orientation='vertical' flexItem />

        <Tooltip
          title={
            <>
              <Typography color='inherit'>Export Data for</Typography>
              <Typography color='inherit'>selected date range</Typography>
            </>
          }
          size={'small'}
        >
          <div>
            <IconButton
              size='small'
              variant='text'
              {...exportDataButtonProps}
              icon={<ExportIcon color='primary' fontSize='large' />}
            ></IconButton>
          </div>
        </Tooltip>
        <Tooltip title='Refresh' id='refreshIcon'>
          <div>
            <IconButton
              sx={{
                '.MuiButton-startIcon': {
                  margin: 'auto',
                  svg: {
                    padding: 0
                  }
                }
              }}
              icon={
                <Refresh
                  fontSize='large'
                  sx={{
                    animation: isLoading
                      ? 'spin 1s linear infinite'
                      : undefined,
                    '@keyframes spin': {
                      '0%': {
                        transform: 'rotate(-360deg)'
                      },
                      '100%': {
                        transform: 'rotate(0deg)'
                      }
                    }
                  }}
                />
              }
              size='small'
              variant='text'
              onClick={onRefresh}
            />
          </div>
        </Tooltip>
        <DefaultActions
          rows={rows}
          columns={columns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
      </Grid>
    </div>
  )
}

export default TableActions
