import React from 'react'
import { Box } from '@mui/material'
import Dialog from './../../../components/Dialog'
import Button from './../../../components/Button'
import BulkUpload from './../../../components/BulkUpload'

import Tabs from '../../../components/Tabs'

const UploadSheetDialog = ({
  tabConfig,
  open,
  onClose,
  tab,
  onTabChange,
  onCancelClick,
  onUploadClick,
  handleUploadedFile,
  onDownloadTemplateClick
}) => {
  return (
    <>
      <Dialog
        width={840}
        height={388}
        open={open}
        onClose={onClose}
        showDialogFooter={true}
        // title={`Upload ${currentTabConfig.title} Sheet`}
        title={`Upload  Sheet`}
        buttonAction={
          <Box display={'flex'} gap={3}>
            <Button onClick={onCancelClick} text='Cancel' variant='outlined' />
            <Button onClick={onUploadClick} text='Upload' color='success' />
          </Box>
        }
      >
        <Tabs
          width={50}
          tabs={tabConfig}
          size={'small'}
          initialTabValue={tab}
          onTabChange={onTabChange}
          tabSx={{ maxWidth: 1000 }}
        />

        <BulkUpload
          onSuccessfullyLoaded={handleUploadedFile}
          showHeader={false}
          footerProps={{
            showTemplateDownload: true,
            onTemplateDownload: onDownloadTemplateClick
          }}
          containerProps={{
            style: {
              width: '600px',
              height: 'calc(100% - 36px)',
              border: 'none',
              margin: 'auto'
            }
          }}
        />
      </Dialog>
      {/* dialogs */}
      {/* Toggle tabs between Dispatch and bulk upload */}
      {/* bulk upload component */}
      {/* cancel upload button */}
    </>
  )
}

export default UploadSheetDialog
