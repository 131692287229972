import Tooltip from './Tooltip';
import IconButton from './IconButton';
import DeleteIcon from '../icons/DeleteIcon';
import DownloadIcon from '../icons/DownloadIcon';
import {
  Grid,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

const shortFileName = (name) => {
  if (name.length < 24) return name;
  const extension = name.split('.').slice(-1)[0];
  return `${name.slice(0, 10)}...${extension}`;
};
const File = ({ file, name, onUploadComplete, onDelete }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (progress < 100) {
      setTimeout(() => {
        setProgress(progress + 10);
      }, 100);
    } else {
      setUploading(true);
      onUploadComplete({ data: 'the data has been uploaded successfully' });
    }
  }, [progress]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 400,
      }}
    >
      {uploading && (
        <Grid item xs={12}>
          {/* <img src={require('../../../../assets/UploadSuccess.png').default} alt="upload success" /> */}
        </Grid>
      )}
      <Grid item>
        <Tooltip title={name}>
          <Typography
            variant="body1"
            sx={{
              lineBreak: 'anywhere',
            }}
          >
            {shortFileName(name)}
          </Typography>
        </Tooltip>
      </Grid>

      {uploading ? (
        <Grid
          container
          item
          style={{ display: 'flex', width: 'max-content' }}
          spacing={1}
        >
          <Grid item>
            <IconButton
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<DownloadIcon />}
            />
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              variant="outlined"
              color="error"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <LinearProgress
            sx={{
              width: 100,
              borderRadius: 1,
              [`& .${linearProgressClasses.bar}`]: {
                bgcolor: 'primary.dark',
                borderRadius: 1,
              },
            }}
            variant="determinate"
            value={progress}
            color="primary"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default File;
