import React from 'react'
import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import MuiBox from '@mui/material/Box'
import { useControlled } from '@mui/material'

const Tabs = ({
  tabs,
  size = 'small',
  width = 100,
  onTabChange,
  value,
  sx,
  tabSx,
  initialTabValue,
  disabled = false,
  ...otherProps
}) => {
  const theme = useTheme()
  const [currValue, setCurrentValue] = useControlled({
    controlled: value,
    default: initialTabValue,
    name: 'Tabs'
  })

  const handleChange = (_, tab) => {
    setCurrentValue(tab)
    onTabChange && onTabChange(tab)
  }

  return (
    <MuiBox sx={{ borderColor: 'divider' }}>
      <MuiTabs
        value={currValue}
        indicatorColor='primary'
        textColor='primary'
        onChange={handleChange}
        aria-label='disabled tabs example'
        sx={{
          minHeight: 0,
          height: size === 'small' ? '36px' : '40px',
          '& .MuiTabs-indicator': {
            backgroundColor: `${
              !disabled ? theme.palette.primary.main : theme.palette.grey[350]
            }`
          },
          ...sx,
          '& .MuiTabs-root': {
            minHeight: '155px !important'
          }
        }}
        {...otherProps}
      >
        {tabs?.map((item, index) => (
          <MuiTab
            key={index}
            {...otherProps}
            disabled={disabled}
            {...item}
            sx={{
              minHeight: 0,
              height: size === 'small' ? '36px' : '40px',
              color: theme.palette.common.black,
              borderBottom: `1px solid ${theme.palette.grey[350]}`,
              textTransform: 'none',
              ...theme.typography.h5,
              width: `${width}%`,
              '&:hover': {
                color: theme.palette.primary.main
              },

              ...tabSx
            }}
          />
        ))}
      </MuiTabs>
    </MuiBox>
  )
}

export default Tabs
