import { useEffect, useMemo, useState } from 'react'
import { endOfDay, subDays } from 'date-fns'
import { Box, CircularProgress } from '@mui/material'
import usePagination from '../../hooks/usePagnation'
import useActive from '../../hooks/useActive'
import useToggle from '../../hooks/useToggle'
import {
  useDownloadReportMutation,
  useGetConfigQuery,
  useGetPenaltyDataQuery,
  useRecalculatePenaltyMutation,
  useUpdatePenaltyMutation,
  useUploadDispatchDataMutation,
  useUploadGateDataMutation
} from '../../redux/penalty'
import {
  formatDate,
  convertExcelToData,
  generateSheetWithErrorData
} from './utils'
import useForm from '../../hooks/useForm'
import { EditPenalty } from './components/TableActions'
import {
  errorToast,
  pendingSuccessToast,
  successToast
} from '../../components/Toast/toast'
import Success from '../../icons/Success'

// function for Writing logics Related to Home Page
function useHome() {
  // pagination Props
  const { page, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination({
      initialPage: 1,
      initialPageSize: 25
    })

  // form for useGetPenaltyDataQuery body
  const form = useForm({
    startDate: subDays(new Date(), 30).toISOString(),
    endDate: endOfDay(new Date()).toISOString(),
    search_text: ''
  })

  const { search_text, ...dateFields } = form.form

  const parsedFormData = useMemo(
    () => ({ ...form.form, page, perPage: pageSize }),
    [form.form, page, pageSize]
  )
  const {
    // isFetching: isTableDataFetching,
    isLoading: isTableDataLoading,
    data: penaltyData,
    refetch: refetchPenaltyData
  } = useGetPenaltyDataQuery(parsedFormData)

  const {
    isFetching: isTableHeaderFetching,
    // isLoading: isTableHeadersLoading,
    data: config
  } = useGetConfigQuery('')

  const recalculateDialog = useToggle(false)
  const exportDataDialog = useToggle(false)
  const editDialog = useToggle(false)
  // donwload sheet
  const [getSheetURL] = useDownloadReportMutation()

  const exportReportButtonClick = () => {
    pendingSuccessToast(
      getSheetURL(dateFields).unwrap(),
      {
        pending: {
          render() {
            return 'Download in progress'
          },
          icon: (
            <Box sx={{ hieght: '20px' }}>
              <CircularProgress size='1rem' color='inherit' />
            </Box>
          )
        },
        success: {
          render(data) {
            const link = document.createElement('a')
            link.href = data.data
            link.click()
            return 'Downloaded successfully'
          },
          icon: (
            <Success
              htmlColor='#fff'
              sx={{
                '& path:last-child': {
                  stroke: (theme) => '#008F66'
                }
              }}
            />
          )
        },
        error: {
          render(data) {
            return `Error In downloading: ${data?.data?.data?.message}`
          },
          icon: false
        }
      },
      { autoClose: 8000 }
    )
  }

  // recalculate button
  const [recalculate] = useRecalculatePenaltyMutation()

  const onRecalculateButtonClick = () => {
    pendingSuccessToast(
      recalculate(dateFields).unwrap(),
      {
        pending: {
          render() {
            return 'Recalculation in progress'
          },
          icon: (
            <Box>
              <CircularProgress size='1rem' color='inherit' />
            </Box>
          )
        },
        success: {
          render() {
            return 'Recalculation successfully, please refresh after sometime to see the latest data'
          },
          icon: (
            <Success
              htmlColor='#fff'
              sx={{
                '& path:last-child': {
                  stroke: (theme) => '#008F66'
                }
              }}
            />
          )
        },
        error: {
          render() {
            return 'Error In Recalculating'
          },
          icon: false
        }
      },
      { autoClose: 10000 }
    )
  }
  const [updatePenalty] = useUpdatePenaltyMutation()
  const [currentId, setCurrentId] = useState('')

  const onUpdatePenaltyData = (body) => {
    pendingSuccessToast(updatePenalty({ body, currentId }).unwrap(), {
      pending: {
        render() {
          return 'Updating penalty'
        },
        icon: (
          <Box>
            <CircularProgress size='1rem' color='inherit' />
          </Box>
        )
      },
      success: {
        render() {
          return 'Penalty successfully updated'
        },
        icon: (
          <Success
            htmlColor='#fff'
            sx={{
              '& path:last-child': {
                stroke: (theme) => '#008F66'
              }
            }}
          />
        )
      },
      error: {
        render() {
          return 'Error In updating penalty'
        },
        icon: false
      }
    })
  }

  const [bulkUploadedShipmentData, setBulkUploadedShipmentData] = useState([])

  const handleUploadedFile = (file) => {
    convertExcelToData(file[0])
      .then((res) => {
        //TODO: validate parsed data here before setting
        console.log(res)
        setBulkUploadedShipmentData(res.jsonData)
      })
      .catch((e) => console.log(e))
  }

  const {
    table_headers: tableHeaders,
    req_gate_fields: reqGateFields,
    req_dispatch_fields: reqDispatchFields,
    gate_reg_sheet_template: gateSheetTemplate,
    dispatch_sheet_template: dispatchSheetTemplate,
    editable_fields: editableFields
  } = config ?? {}
  const columns = useMemo(
    () => [
      // getting columns form config
      ...(tableHeaders || []).map(
        ({ field, headerName, sortable, format, width }) => ({
          field,
          headerName,
          sortable,
          valueFormatter: (params) => {
            if (!params.value) return '-'
            // if (format === 'date') return formatDate(params.value)
            return params.value
          },
          width: width
        })
      ),
      {
        headerName: ' ',
        field: 'editPenalty',
        renderCell: (params) => {
          return (
            <EditPenalty
              onClick={() => {
                editDialog.setTrue()
                setCurrentId(params.row._id)
              }}
            />
          )
        }
      }
    ],

    [tableHeaders]
  )

  // search box props
  const [search, setSearch] = useState('')
  const globalSearchHandler = () => {
    handlePageChange(1)
    form.handleValue('search_text')(search)
  }
  const onSearchCutIconClick = () => {
    setSearch('')
    // TODO: better way?
    form.handleValue('search_text')('')
  }

  // upload sheet mutations
  const [uploadDispatchSheet] = useUploadDispatchDataMutation()
  const [uploadGateSheet] = useUploadGateDataMutation()

  // value not being used currently, setting but not using
  const [missingFields, setMissingFields] = useState([])

  const tabConfig = [
    { id: 0, label: 'Gate Sheet Upload' },
    { id: 1, label: 'Dispatch Sheet Upload' }
  ]

  const activeTab = useActive(0)
  const UploadSheetDialog = useToggle(false)

  const onUploadClick = () => {
    if (bulkUploadedShipmentData.length === 0) {
      errorToast(`Uploaded sheet is empty`)
      UploadSheetDialog.setFalse()
      return
    }
    const keys = Object.keys(bulkUploadedShipmentData[0])

    if (activeTab.active === 0) {
      // Gate Sheet
      if (reqGateFields.every((el) => keys.indexOf(el) > -1)) {
        // if all required columns are present
        pendingSuccessToast(
          uploadGateSheet({ data: bulkUploadedShipmentData }).unwrap(),
          {
            pending: {
              render() {
                return bulkUploadedShipmentData.length > 1
                  ? `Processing ${bulkUploadedShipmentData.length} data might take some time please wait`
                  : 'Uploading data'
              },
              icon: (
                <Box>
                  <CircularProgress size='1rem' color='inherit' />
                </Box>
              )
            },
            success: {
              render(data) {
                const rejectedRows = data?.data || []
                if (rejectedRows.length === 0)
                  return 'Gate sheet uploaded successfully'
                else {
                  generateSheetWithErrorData(rejectedRows, 'gateSheetErrorData')
                  return `Partially uploaded, ${rejectedRows.length} row(s) containing error was rejected and download in new sheet`
                }
              },
              icon: (
                <Success
                  htmlColor='#fff'
                  sx={{
                    '& path:last-child': {
                      stroke: (theme) => '#008F66'
                    }
                  }}
                />
              )
            },
            error: {
              render(error) {
                console.log(error)
                return `Failed to upload data ${
                  error?.data?.data?.message || ''
                }`
              },
              icon: false
            }
          },
          { autoClose: 8000 }
        )
      } else {
        // not all req columns are present
        let missing = reqGateFields.filter((el) => keys.indexOf(el) === -1)
        setMissingFields(missing)
        errorToast(
          `Failed to upload data, missing column: ${missing.join(',')} `,
          { autoClose: 7000 }
        )
      }
    }

    // Dispatch Sheet
    if (activeTab.active === 1) {
      if (reqDispatchFields.every((el) => keys.indexOf(el) > -1)) {
        // if all required columns are present
        pendingSuccessToast(
          uploadDispatchSheet({ data: bulkUploadedShipmentData }).unwrap(),
          {
            pending: {
              render() {
                return bulkUploadedShipmentData.length > 1000
                  ? `Processing ${bulkUploadedShipmentData.length} data might take some time please wait`
                  : 'Uploading data'
              },
              icon: (
                <Box>
                  <CircularProgress size='1rem' color='inherit' />
                </Box>
              )
            },
            success: {
              render(data) {
                const rejectedRows = data?.data || []
                if (rejectedRows.length === 0)
                  return 'Dispatch sheet uploaded successfully'
                else {
                  generateSheetWithErrorData(
                    rejectedRows,
                    'dispatchSheetErrorData'
                  )
                  return `Partially uploaded, ${rejectedRows.length} row(s) containing error was rejected and download in new sheet`
                }
              },
              icon: (
                <Success
                  htmlColor='#fff'
                  sx={{
                    '& path:last-child': {
                      stroke: (theme) => '#008F66'
                    }
                  }}
                />
              )
            },
            error: {
              render(error) {
                console.log(error)
                return `Failed to upload data ${
                  error?.data?.data?.message || ''
                }`
              },
              icon: false
            }
          },
          { autoClose: 8000 }
        )
      } else {
        // not all req columns are present
        let missing = reqDispatchFields.filter((el) => keys.indexOf(el) === -1)
        setMissingFields(missing)
        errorToast(
          `Failed to upload data, missing column: ${missing.join(',')} `
        )
      }
    }
    UploadSheetDialog.setFalse()
  }

  const onDownloadTemplateClick = () => {
    const templateSheetLink =
      activeTab.active === 0 ? gateSheetTemplate : dispatchSheetTemplate
    const link = document.createElement('a')
    link.href = templateSheetLink
    link.click()
    UploadSheetDialog.setFalse(0)
  }

  // useEffect(() => {
  //   if (!penaltyData)
  //     errorToast('Services are down, apologies for inconvenience', {
  //       autoClose: 5000
  //     })
  // }, [penaltyData?.data?.[0]?._id])

  return {
    page,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
    tableHeaders: columns || [],
    penaltyData: penaltyData?.data || [],
    total: penaltyData?.total_count || 0,
    searchBoxProps: {
      search,
      setSearch,
      globalSearchHandler,
      onSearchCutIconClick
    },
    editPenaltyDialogProps: {
      open: editDialog.toggle,
      onClose: () => {
        editDialog.setFalse()
      },
      onSave: (body) => {
        console.log(body)
        onUpdatePenaltyData(body)
      },
      penalties: editableFields
    },
    datePickerProps: {
      startDate: form.form.startDate,
      endDate: form.form.endDate,
      handleChange: form.handleValue
    },
    uploadSheetDialogProps: {
      tabConfig,
      open: UploadSheetDialog.toggle,
      onClose: () => {
        UploadSheetDialog.setFalse()
      },
      tab: activeTab.active,
      onTabChange: (tab) => {
        activeTab.handleActive(tab)
      },
      onCancelClick: () => {
        UploadSheetDialog.setFalse()
      },
      onUploadClick: onUploadClick,
      handleUploadedFile,
      onDownloadTemplateClick
    },
    uploadSheetButtonProps: {
      onClick: (index) => {
        UploadSheetDialog.setTrue()
        activeTab.handleActive(index)
      },
      handleButtonClick: () => {
        UploadSheetDialog.setTrue()
        activeTab.handleActive(0)
      }
    },
    recalculateDialogProps: {
      open: recalculateDialog.toggle,
      onClose: () => recalculateDialog.setFalse(),
      onSave: () => {
        onRecalculateButtonClick()
        recalculateDialog.setFalse()
      },
      startDate: formatDate(form.form.startDate, { format: 'dd-MMM-yyyy' }),
      endDate: formatDate(form.form.endDate, { format: 'dd-MMM-yyyy' })
    },

    exportDataDialogProps: {
      open: exportDataDialog.toggle,
      onClose: () => exportDataDialog.setFalse(),
      onSave: () => {
        exportReportButtonClick()
        exportDataDialog.setFalse()
      },
      startDate: formatDate(form.form.startDate, { format: 'dd-MMM-yyyy' }),
      endDate: formatDate(form.form.endDate, { format: 'dd-MMM-yyyy' })
    },
    exportDataButtonProps: {
      onClick: () => exportDataDialog.setTrue()
    },
    recalculateButtonProps: {
      onClick: () => recalculateDialog.setTrue()
    },
    exportReportButtonClick,
    isLoading: isTableHeaderFetching || isTableDataLoading,
    onRefresh: () => {
      refetchPenaltyData()
        .then(() => successToast('Successfully Refreshed'))
        .catch((e) => errorToast('Something went wrong'))
    }
  }
}

export default useHome
