import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UnCheckedIcon = ({ sx, ...otherProps }) => {
  return (
    <SvgIcon
      {...otherProps}
      sx={{
        fill: 'none',
        ...sx,
      }}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 3.79086 1.79086 2 4 2H12C14.2091 2 16 3.79086 16 6V14C16 16.2091 14.2091 18 12 18H4C1.79086 18 0 16.2091 0 14V6Z"
        fill="inherit"
        stroke="currentColor"
        strokeWidth="1px"
        transform="translate(1 0)"
      />
    </SvgIcon>
  );
};

export default UnCheckedIcon;
