import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ExportIcon = ({ color, fontSize, className, style }) => {
  return (
    <SvgIcon
      style={style}
      color={color}
      fontSize={fontSize}
      className={className}
      viewBox="0 0 16 16"
    >
      <path
        d="M10.916 13.1144V14.1205C10.916 14.4745 10.6272 14.754 10.2825 14.754H2.23358C1.88889 14.754 1.6001 14.4745 1.6001 14.1205V2.23345C1.6001 1.87945 1.88889 1.59998 2.23358 1.59998H6.71451C6.89151 1.59998 7.06851 1.6745 7.18962 1.80492L10.7483 5.7269C10.8601 5.84801 10.916 6.00638 10.916 6.16475V8.17698V10.1892"
        stroke="currentColor"
        fill="transparent"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65869 1.63721V5.73619C6.65869 6.09019 6.94748 6.36967 7.29217 6.36967H10.916"
        stroke="currentColor"
        fill="transparent"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50635 11.6518H14.4001L12.6767 9.89111"
        stroke="currentColor"
        fill="transparent"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4002 11.6519L12.686 13.366"
        stroke="currentColor"
        fill="transparent"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ExportIcon;
