import * as React from 'react'
import { createSvgIcon, styled } from '@mui/material'

export default styled(
  createSvgIcon(
    <React.Fragment>
      <mask id="colSelectorOutlinedA" fill="transparent">
        <rect width="4.961" height="17.778" x="2" y="3" rx=".5" />
      </mask>
      <rect
        width="4.961"
        height="17.778"
        x="2"
        y="3"
        stroke="currentColor"
        strokeWidth="2"
        mask="url(#colSelectorOutlinedA)"
        rx=".5"
      />
      <mask id="colSelectorOutlinedB" fill="transparent">
        <rect width="4.961" height="17.778" x="9.519" y="3" rx=".5" />
      </mask>
      <rect
        width="4.961"
        height="17.778"
        x="9.519"
        y="3"
        stroke="currentColor"
        strokeWidth="2"
        mask="url(#colSelectorOutlinedB)"
        rx=".5"
      />
      <mask id="colSelectorOutlinedC" fill="transparent">
        <rect width="4.961" height="17.778" x="17.039" y="3" rx=".5" />
      </mask>
      <rect
        width="4.961"
        height="17.778"
        x="17.039"
        y="3"
        stroke="currentColor"
        strokeWidth="2"
        mask="url(#colSelectorOutlinedC)"
        rx=".5"
      />
    </React.Fragment>,
    'ColumnSelector'
  )
)({
  fill: 'transparent',
  stroke: 'white'
})
