import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';


const SearchIcon = ({ color, ...otherProps }) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="primary"
      {...otherProps}
    >
      <path
        d="M7.47502 13.3499C10.7197 13.3499 13.3499 10.7196 13.3499 7.47493C13.3499 4.2303 10.7197 1.60001 7.47502 1.60001C4.23039 1.60001 1.6001 4.2303 1.6001 7.47493C1.6001 10.7196 4.23039 13.3499 7.47502 13.3499Z"
        stroke={color || '#2C63E5'}
        fill="transparent"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.834 11.8241L14.3999 14.3999"
        stroke={color || '#2C63E5'}
        fill="transparent"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.47502 13.3499C10.7197 13.3499 13.3499 10.7196 13.3499 7.47493C13.3499 4.2303 10.7197 1.60001 7.47502 1.60001C4.23039 1.60001 1.6001 4.2303 1.6001 7.47493C1.6001 10.7196 4.23039 13.3499 7.47502 13.3499Z"
        stroke={color || '#2C63E5'}
        fill="transparent"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4751 3.45264C9.69429 3.45264 11.4776 5.22601 11.5073 7.4353"
        stroke={color || '#2C63E5'}
        fill="transparent"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4751 3.45264C9.69429 3.45264 11.4776 5.22601 11.5073 7.4353"
        stroke={color || '#2C63E5'}
        fill="transparent"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SearchIcon;
