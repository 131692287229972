import React, { useEffect, useState } from 'react'

import Checkbox from '../Checkbox'
import IconButton from '../IconButton'
import MuiBox from '@mui/material/Box'
import ColumnSelector from '../../icons/ColumnSelector'
import ReorderColumn from '../../icons/ReorderColumn'
import useForm from '../../hooks/useForm'
import { ReactSortable } from 'react-sortablejs'
import Button from '../Button'
import { Box, Grid, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '../Dialog'

import useToggle from '../../hooks/useToggle'

const setInitialState = (selectedColumns) => {
  return selectedColumns
    ?.filter((item) => item?.headerName?.trim())
    ?.map((column, index) => ({
      ...column,
      id: index,
      ...(column.disableColumnInFilterMenu ? { filtered: true } : {})
    }))
}

export const SelectDisplayItemsDialog = ({
  columns,
  setSelectedColumns,
  selectedColumns
}) => {
  const displayItemDialog = useToggle(false)

  const { form, handleToggle, setFields } = useForm(
    columns?.reduce((a, b) => {
      a[b.field] = true
      return a
    }, {})
  )

  useEffect(() => {
    setFields(
      columns?.reduce((a, b) => {
        a[b.field] = true
        return a
      }, {})
    )
  }, [columns])

  const [state, setState] = useState(setInitialState(selectedColumns))

  useEffect(() => {
    setState(setInitialState(selectedColumns))
  }, [selectedColumns])

  return (
    <>
      <Tooltip
        title={<Typography color='inherit'>Select Display Columns</Typography>}
      >
        <div>
          <IconButton
            variant='text'
            size='small'
            icon={<ColumnSelector />}
            onClick={() => displayItemDialog.setTrue()}
          />
        </div>
      </Tooltip>
      <Box
        display={'flex'}
        justifyContent='center'
        alignItems={'center'}
        height='100%'
      >
        <Dialog
          open={displayItemDialog.toggle}
          title={'Select Display Items'}
          onClose={() => displayItemDialog.setFalse()}
          height={500}
          width={704}
          buttonAction={
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item>
                <Button
                  text='Reset'
                  size='small'
                  variant='text'
                  color='error'
                  onClick={() => {
                    setState(
                      setInitialState(
                        columns?.filter((item) => item?.headerName !== ' ')
                      )
                    )
                    setFields(
                      columns?.reduce((a, b) => {
                        a[b.field] = true
                        return a
                      }, {})
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  text='Cancel'
                  size='small'
                  variant='outlined'
                  color='secondary'
                  onClick={() => displayItemDialog.setFalse()}
                />

                <Button
                  text='Save'
                  size='small'
                  variant='contained'
                  color='success'
                  sx={{ marginLeft: '24px' }}
                  onClick={() => {
                    setSelectedColumns(
                      [
                        // removing id while savaing the columns
                        ...state.map(
                          ({ id, filtered, ...keepAttrs }) => keepAttrs
                        ),
                        // appending viewDetails and timeline(whise headerName is " ")
                        ...(columns?.filter(
                          (item) => item?.headerName === ' '
                        ) || {})
                      ]
                        // filtering out the cols which are unchecked
                        ?.filter(({ field }) => {
                          if (field in form) return form[field]
                          return true
                        })
                    )
                    displayItemDialog.setFalse()
                  }}
                />
              </Grid>
            </Grid>
          }
        >
          <Box p={2}>
            <Box px={2}>
              <Typography variant='h6' color='textSecondary'>
                Add/remove column in the table using the checkbox
              </Typography>

              <Typography
                variant='h6'
                color='textSecondary'
                sx={{ margin: '8px 0px' }}
              >
                Drag to reorder column position in the table
              </Typography>
            </Box>

            <Box py={1} sx={{ display: 'flex', flexDirection: 'column' }}>
              <ReactSortable
                list={state}
                setList={setState}
                style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}
              >
                {state?.map((item) => (
                  <div
                    key={item.headerName}
                    style={{
                      cursor: 'move',
                      userSelect: 'none',
                      width: 'max-content',
                      backgroundColor: item.disableColumnInFilterMenu
                        ? '#E3E3E3'
                        : '#FFFFFF'
                    }}
                  >
                    <MuiBox
                      key={item?.headerName}
                      sx={{
                        height: '40px',
                        width: '312px',
                        borderRadius: '4px',
                        border: `1px solid #E3E3E3`,

                        padding: '0 16px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Checkbox
                        label={item?.headerName ?? ''}
                        size='medium'
                        checked={form[item.field]}
                        onClick={handleToggle(item.field)}
                        disabled={item.disableColumnInFilterMenu}
                      />

                      <IconButton
                        size='small'
                        variant='text'
                        icon={
                          <ReorderColumn color='primary' fontSize='large' />
                        }
                        sx={{ cursor: 'move' }}
                      ></IconButton>
                    </MuiBox>
                  </div>
                ))}
              </ReactSortable>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  )
}

export default SelectDisplayItemsDialog
