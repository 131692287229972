import MuiTooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

const Tooltip = ({
  title,
  color = 'dark',
  placement = 'top',
  size = 'small',
  ...otherProps
}) => {
  const theme = useTheme();
  return (
    <MuiTooltip
      title={title}
      placement={placement}
      arrow
      {...otherProps}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor:
              color === 'dark' ? theme.palette.primary.dark : '#ffffff',
            color: color === 'dark' ? '#ffffff' : '#1A1A1A',
            height: 'auto',
            minHeight: size === 'medium' ? '18px' : '14px',
            border: `1px solid ${theme.palette.primary[200]}`,
            borderRadius: '4px',
            minWidth: size === 'small' ? '35px' : '38px',
            textAlign: 'center',
            ...(size === 'small'
              ? {
                  ...theme.typography.body2,
                }
              : {
                  ...theme.typography.body1,
                }),
            padding: size === 'medium' ? '8px 16px' : '4px 8px',
            ...(placement === 'top' && {
              marginBottom: '4px !important',
            }),
            ...(placement === 'bottom' && {
              marginTop: '4px !important',
            }),
          },
        },
        arrow: {
          sx: {
            color: color === 'dark' ? theme.palette.primary.dark : '#ffffff',
            position: 'absolute',
            // top: size === 'medium' ? '34.2px' : '22.2px',
            top: 'auto',
            // top: size === 'medium' ? '34.2px' : '',
            '&:before': {
              border: `.1px solid`,
              borderColor: theme.palette.primary[200],
              borderRadius: '2px',
            },
          },
        },
      }}
    >
      {otherProps?.children}
    </MuiTooltip>
  );
};

export default Tooltip;
