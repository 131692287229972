import * as React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
      d="M22.83 11.997c0 1.073-.979 1.993-1.297 2.96-.33 1.003-.083 2.312-.696 3.162-.614.849-1.947 1.014-2.784 1.64-.838.612-1.404 1.816-2.418 2.146-.979.318-2.135-.319-3.22-.319-1.073 0-2.241.637-3.22.319-1.003-.33-1.58-1.534-2.418-2.147-.85-.613-2.17-.79-2.784-1.64-.613-.837-.365-2.146-.696-3.16C2.98 13.99 2 13.07 2 11.996s.979-1.993 1.297-2.96c.33-1.003.083-2.312.696-3.162.614-.849 1.947-1.014 2.784-1.64.85-.6 1.415-1.816 2.418-2.146.979-.306 2.147.319 3.22.319 1.073 0 2.241-.637 3.22-.319 1.003.33 1.58 1.534 2.418 2.147.85.613 2.17.79 2.784 1.64.613.837.365 2.146.695 3.16.319.968 1.298 1.888 1.298 2.961Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
      d="m7.59 12.834 1.959 1.958a1.232 1.232 0 0 0 1.745 0l5.945-5.944"
    />
  </React.Fragment>,
  'Success'
)
