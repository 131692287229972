import Header from './TableHeader'
import Footer from './TableFooter'
import React, { useEffect, useState } from 'react'

import { DataGrid } from '@mui/x-data-grid'
import { Box, CircularProgress, useTheme } from '@mui/material'

const Table = ({
  columns = [],
  rows = [],
  headerProps,
  footerProps,
  tableProps,
  components,
  componentsProps,
  containerProps = {},
  hideFooter = false,
  isLoading
}) => {
  const theme = useTheme()
  const [selectedColumns, setSelectedColumns] = useState(columns)

  useEffect(() => {
    setSelectedColumns(columns)
  }, [columns])

  return (
    <Box
      {...containerProps}
      sx={[
        {
          backgroundColor: '#fff',
          width: '100%',
          height: '100%',
          borderRadius: '8px'
          // boxShadow: "0px 2px 8px 0px #1A1A1A33",
        },
        ...(Boolean(containerProps.sx) && Array.isArray(containerProps.sx)
          ? containerProps.sx
          : []),
        Boolean(containerProps.sx) && !Array.isArray(containerProps.sx)
          ? containerProps.sx
          : {}
      ]}
    >
      {isLoading && (
        <div
          style={{
            height: 'calc(100%)',
            width: 'calc(100%)',
            position: 'absolute',
            display: 'contents',
            backdropFilter: 'brightness(0.9)',
            zIndex: 9999
          }}
        >
          <CircularProgress
            size='2rem'
            sx={{
              position: 'absolute',
              top: 'calc(50% )',
              left: 'calc(50%)',
              zIndex: 9999
            }}
          />
        </div>
      )}
      <DataGrid
        sx={[
          {
            '& .MuiDataGrid-columnHeaderWrapper, .MuiDataGrid-columnHeaders, .MuiDataGrid-columnsContainer':
              {
                height: 44,
                minHeight: '44px !important',
                maxHeight: '44px !important',
                lineHeight: '44px !important',
                backgroundColor: '#F8F8F8'
              },
            '& .MuiDataGrid-columnsContainer': {
              backgroundColor: '#F8F8F8',
              borderBottom: '1px solid #E3E3E3'
            },
            '& .MuiDataGrid-row, .MuiDataGrid-cell': {
              minHeight: '40px !important',
              maxHeight: '40px !important'
            },
            '& .MuiDataGrid-row': {
              '&:hover': {
                backgroundColor: 'primary.contrastText'
              }
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
              {
                outline: 'none !important'
              }
          },
          {
            '& .MuiDataGrid-columnHeader': {
              ...theme.typography.h5,
              height: 44,
              boxSizing: 'border-box',
              '& .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }
          },
          {
            '& 	.MuiDataGrid-cell': {
              minHeight: '40px !important',
              maxHeight: '40px !important',
              lineHeight: '40px !important',
              paddingLeft: '16px !important',
              "$ [aria-selected='true']": {
                backgroundColor: theme.palette.primary.contrastText
              },
              "$ [aria-selected='false']": {
                backgroundColor: theme.palette.common.white
              },
              ...theme.typography.body1
            }
          }
        ]}
        hideFooter={hideFooter}
        columns={selectedColumns}
        rows={rows}
        rowHeight={40}
        components={{
          Toolbar: Header,
          Footer,
          ...components
        }}
        componentsProps={{
          toolbar: {
            columns,
            selectedColumns,
            setSelectedColumns,
            rows,
            ...headerProps
          },
          footer: {
            // @ts-ignore
            columns,
            rows,
            ...footerProps
          },
          ...componentsProps
        }}
        disableRowSelectionOnClick
        {...tableProps}
      />
    </Box>
  )
}

export default Table
