import Button from './Button'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import File from './File'

const DefaultHeader = ({ title }) => {
  return (
    <div style={{ marginBottom: 32 }}>
      <Typography variant='h4' color='textSecondary'>
        {title}
      </Typography>
    </div>
  )
}

const DefaultFooter = ({ showTemplateDownload, onTemplateDownload }) => {
  return (
    <>
      {showTemplateDownload && (
        <Typography variant='body1' style={{ margin: '8px 0' }}>
          Data in the file should be in correct format.{' '}
          <span
            style={{ color: '#2C63E5', cursor: 'pointer' }}
            onClick={onTemplateDownload}
          >
            Download the .xlsx template here.
          </span>
        </Typography>
      )}
    </>
  )
}

const BulkUpload = ({
  defaultValue = [],
  containerProps,
  bulkUploadProps,
  showHeader = true,
  showFooter = true,
  onSuccessfullyLoaded,
  dropzoneProps = {},
  onDelete,
  onChange,
  dropText = 'or drop file here',
  onDropText = 'Drop the files here ...',
  Header = DefaultHeader,
  headerProps = {
    title: 'Upload Excel/CSV'
  },
  Footer = DefaultFooter,
  footerProps = {
    showTemplateDownload: true,
    onTemplateDownload: undefined
  }
}) => {
  const theme = useTheme()
  const [files, setFiles] = useState(defaultValue)

  const { getRootProps, getInputProps, isDragActive, inputRef, acceptedFiles } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setFiles(acceptedFiles)
      },
      accept: {
        '.csv': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.ms-excel': []
      },
      noClick: true,
      // onDropRejected: () => toast.error('Invalid file!!!'),
      ...dropzoneProps
    })

  useEffect(() => {
    console.log('onChange of bulk upload is running', acceptedFiles)
    onChange?.(acceptedFiles)
  }, [acceptedFiles])

  return (
    <Box
      sx={{
        width: 770,
        height: 504,
        borderRadius: 1,
        border: '1px solid #E3E3E3',
        padding: '32px 40px',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box'
      }}
      {...containerProps}
    >
      {showHeader && <Header {...headerProps} />}
      <div
        style={{
          width: '100%',
          height: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          {...getRootProps()}
          id='bulk-upload'
          style={{
            height: '100%',
            width: '100%',
            flexGrow: 1,
            backgroundColor: theme.palette.primary.contrastText,
            border: '2px dashed black',
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          {...(bulkUploadProps ?? {})}
        >
          {files.length ? (
            files.map((file, index) => (
              <Grid
                container
                style={{ width: 'max-content' }}
                key={`${file.name} + ${index}`}
              >
                <Grid item>
                  <File
                    file={file}
                    name={file.name}
                    onUploadComplete={(res) => {
                      onSuccessfullyLoaded?.([file], index, files)
                    }}
                    onDelete={() => {
                      setFiles((files) =>
                        files.filter((file, i) => i !== index)
                      )
                      onDelete?.(files, file, index)
                    }}
                  />
                </Grid>
              </Grid>
            ))
          ) : (
            <>
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography variant='body2'>{onDropText}</Typography>
              ) : (
                <div>
                  <Button
                    text='Select File'
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={() => inputRef.current?.click()}
                  />
                  <Typography variant='h5' style={{ marginTop: 16 }}>
                    {dropText}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {showFooter && <Footer {...footerProps} />}
      </div>
    </Box>
  )
}

export default BulkUpload
