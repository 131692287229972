import React from 'react'
import { Box } from '@mui/material'
import useHome from './useHome'
import Table from '../../components/table/Table'
import TableActions from './components/TableActions'
import UploadSheetDialog from './components/UploadSheetDialog'
import EditPenaltyDialog from './components/EditPenaltyDialog'
import RecalculateDialog from './components/RecalculateDialog'
import ExportDataDialog from './components/ExportDataDialog'

function NewHome() {
  const {
    tableHeaders,
    penaltyData,
    total,
    page,
    datePickerProps,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
    searchBoxProps,
    uploadSheetDialogProps,
    uploadSheetButtonProps,
    recalculateDialogProps,
    recalculateButtonProps,
    exportDataButtonProps,
    exportDataDialogProps,

    editPenaltyDialogProps,
    isLoading,
    onRefresh
  } = useHome()

  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          padding: '26px 32px',
          // height: 'calc(100% - 70px)',
          height: 'calc(100%)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Table
          headerProps={{
            Actions: TableActions,
            actionsProps: {
              datePickerProps,
              searchBoxProps,
              uploadSheetButtonProps,
              exportDataButtonProps,
              recalculateButtonProps,
              onRefresh,
              isLoading
            }
          }}
          columns={tableHeaders || []}
          rows={penaltyData || []}
          isLoading={isLoading}
          // @ts-ignore
          tableProps={{
            getRowId: (row) => row?.sct_id
          }}
          footerProps={{
            page,
            pageSize,
            onPageChange: handlePageChange,
            onPageSizeChange: (e) => handlePageSizeChange(e?.target?.value),
            count: Math.ceil(total / pageSize),
            total: total
          }}
        />
      </Box>
      <UploadSheetDialog {...uploadSheetDialogProps} />
      <RecalculateDialog {...recalculateDialogProps} />
      <ExportDataDialog {...exportDataDialogProps} />
      <EditPenaltyDialog {...editPenaltyDialogProps} />
    </>
  )
}
export default NewHome
