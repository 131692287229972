import React from 'react'
import MuiDialog from '@mui/material/Dialog'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiTypography from '@mui/material/Typography'
import { IconButton as MuiIconButton } from '@mui/material/'
import MuiCloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

const Dialog = ({
  open = false,
  onClose,
  children,
  buttonAction,
  showDialogFooter = true,
  title,
  height = 144,
  width = 496
}) => {
  const theme = useTheme()
  return (
    <>
      <MuiDialog
        sx={{ overflowY: 'visible' }}
        PaperProps={{
          sx: {
            maxWidth: width
          }
        }}
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: width,
            maxWidth: width,
            padding: '0 40px',
            height: 72,
            boxSizing: 'border-box'
          }}
          id='alert-dialog-title'
        >
          <div>
            <MuiTypography
              variant='h3'
              sx={{ color: '#ffffff' }}
              color='textPrimary'
            >
              {title}
            </MuiTypography>
          </div>
          <MuiIconButton onClick={onClose}>
            <MuiCloseIcon
              sx={{ color: '#ffffff', fontSize: '24px' }}
              style={{ cursor: 'pointer' }}
            />
          </MuiIconButton>
        </Box>
        <MuiDialogContent
          sx={{
            height: height,
            width: 'inherit',
            padding: '0px'
          }}
        >
          {children}
        </MuiDialogContent>
        {showDialogFooter && (
          <MuiDialogActions
            sx={{
              height: 80,
              width: width,
              maxWidth: width,
              borderTop: `1px solid black`,
              borderColor: theme.palette.primary[200],
              backgroundColor: '#F5F8FF',
              display: 'flex',
              alignItems: 'center',
              padding: '0 40px',
              boxSizing: 'border-box'
            }}
          >
            {buttonAction}
          </MuiDialogActions>
        )}
      </MuiDialog>
    </>
  )
}

export default Dialog
